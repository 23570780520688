import Vue from 'vue';
import Component from 'vue-class-component';
import { Action } from 'vuex-class';
import Navbar from '@/components/common/navbar/Navbar.vue';
import FooterExpanded from '@/components/common/footer-expanded/FooterExpanded.vue';
import BackToTop from '@/components/common/back-to-top/BackToTop.component.vue';

@Component({
  components: {
    Navbar,
    FooterExpanded,
    BackToTop,
  },
  metaInfo: {
    title: 'Cookie Policy',
  },
})

export default class CookiePolicy extends Vue {
  get brand(): string {
    return require('../../../whitelabel.config').name;
  }
}
